/* AdventureMap.css */

/* Emoji Icon */
.emoji-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;  /* Increased font size */
  width: 30px;      /* Adjusted width */
  height: 30px;     /* Adjusted height */
}


/* Custom popup styles */
.leaflet-popup-content-wrapper {
  width: 400px; /* Increased width */
  max-width: 95vw; /* Ensures it doesn't exceed 95% of the viewport width on small screens */
}

.leaflet-popup-content {
  width: 100% !important; /* Overrides Leaflet's inline width */
  margin: 13px 19px; /* Adjust padding as needed */
}

/* Adjust input and textarea widths */
.leaflet-popup-content input[type="text"]{
  padding: 5px;
  margin-bottom: 0px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.leaflet-popup-content input[type="number"]{
  width: 80%; /* 90% width minus padding */
  padding: 5px;
  margin-bottom: 0px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.leaflet-popup-content textarea {
  width: 90%; /* 90% width minus padding */
  padding: 5px;
  margin-bottom: 0px;
  box-sizing: border-box; /* Ensures padding is included in width */
  height: 40px;
}

/* Style for form groups */
.leaflet-popup-content .form-group {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.leaflet-popup-content label {
  font-weight: bold;
  width: 120px; /* Increased width for labels */
  margin-right: 10px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.leaflet-popup-content span {
  font-style: italic;
  flex: 1; /* Takes up remaining space */
  word-break: break-word; /* Allows long words to break */
}

/* Button container */
.leaflet-popup-content .button-container {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 10px;
}

/* Button styles */
.leaflet-popup-content button {
  padding: 10px;
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  font-size: 14px;
  width: 48%; /* Adjust as needed to leave a small gap between buttons */
}

/* Update button */
.leaflet-popup-content button[type="submit"] {
  background-color: #4CAF50;
}

/* Delete button */
.leaflet-popup-content .delete-button {
  background-color: #f44336;
}

/* Hover effect for buttons */
.leaflet-popup-content button:hover {
  opacity: 0.8;
}

/* Tightened spacing for info rows */
.leaflet-popup-content .info-row {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.leaflet-popup-content .info-row label {
  font-weight: bold;
  width: 120px; /* Adjusted width to match labels */
  margin-right: 10px;
}

.leaflet-popup-content .info-row span {
  flex: 1;
}

/* Full-width button for adding new spot */
.full-width-button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.full-width-button:hover {
  background-color: #45a049;
}

/* Make the close button (x) larger */
.leaflet-popup-close-button {
  font-size: 24px !important;  /* Increase font size */
  padding: 8px 10px 0 0 !important;  /* Adjust padding */
  color: #333 !important;  /* Change color if desired */
}

/* Increase clickable area */
.leaflet-popup-close-button span {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

/* Optional: change color on hover */
.leaflet-popup-close-button:hover {
  color: #000 !important;
}

/* Prompt message styling */
.prompt-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6a0dada4;
  color: white;
  font-size: 25px;
  padding: 20px 20px;
  border-radius: 8px;
  z-index: 2000;
}

/* Loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Ensure it appears above other elements */
}

.loading-message {
  color: white;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background for contrast */
  padding: 20px;
  border-radius: 10px;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
}

.info-grid.four-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
  padding-left: 0px;
}

.info-grid.four-columns > div {
  flex: 1 1 calc(25% - 5px);
  min-width: 80px;
  font-size: 0.9em;
}

.info-grid.four-columns label {
  font-weight: bold;
  margin-bottom: 2px;
  display: block;
}

.info-grid.four-columns span {
  font-style: italic;
  display: block;
  word-break: break-word;
}

/* Adjust for smaller screens */
@media (max-width: 400px) {
  .info-grid.four-columns > div {
    flex: 1 1 calc(50% - 5px);
  }
}

.info-grid > div {
  display: flex;
  flex-direction: column;
}

.name-field{
  width: 100%;
  grid-column: span 1;
}
.bottom-type-field {
  width: 80%;
  grid-column: span 1;
}

.textarea-container {
  margin-bottom: 10px;
}

/* Input and label styles */
label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

/* Button container styles */
.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.button-container button {
  flex: 1;
  padding: 8px;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

/* ... rest of the existing styles ... */

.edit-location-button {
  width: 30%; 
  padding: 10px;
  background-color: #2196f3; /* Blue color */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.edit-location-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-top: 10px;
}

.button-container button {
  flex: 1; /* This makes all buttons grow equally */
  padding: 8px; /* Reduced from 10px to 5px */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px; /* Reduced from 14px to 12px */
  margin: 0 0px;
  line-height: 1.2; /* Added to ensure text is vertically centered */
}

.button-container button:first-child {
  margin-left: 0; /* Remove left margin for the first button */
}

.button-container button:last-child {
  margin-right: 0; /* Remove right margin for the last button */
}

.button-container .delete-button {
  background-color: #f44336;
}

.button-container .delete-button:hover {
  opacity: 0.8;
}

.button-container button[type="submit"] {
  background-color: #2196f3;
}

.button-container button[type="submit"]:hover {
  background-color: #0056b3;
}

/* ... existing code ... */

.leaflet-control-zoom {
  top: 100px !important; /* Move the zoom control down by 20 pixels */
}

/* General prompt message styling */
.general-prompt {
  font-size: 1em; /* Adjust the font size as needed */
  background-color: black; /* Black background */
  color: white; /* White text */
}

.name-input {
  width: 90%; /* or any specific width you prefer */
}

.bottom-type-input {
  width: 50%; /* or any specific width you prefer */
}

.info-grid .name-input,
.info-grid .bottom-type-input {
  box-sizing: border-box;
  padding: 5px;
}