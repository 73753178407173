/* Basic styling for the body */
body {
    display: flex;
    height: 100vh;
    margin: 0;
    background-color: #121212;
    color: #ffffff;
    font-size: 1em;
    font-family: 'Lato', sans-serif; /* Updated to Lato */
}
/* Styling for the sidebar */
#sidebar {
    width: 300px;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);
    overflow-y: auto;
    background-color: #1e1e1e;
}
/* Styling for the map container */
#map {
    flex: 1;
}
/* Styling for headings */
h3 {
    color: #9c27b0; /* Purple */
    font-family: 'Lato', sans-serif; /* Updated to Lato */
}
/* Styling for form labels */
label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    font-family: 'Lato', sans-serif; /* Updated to Lato */
}
/* Styling for text inputs and textareas */
input[type="text"], textarea {
    width: 95%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
}
/* Styling for buttons */
button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #2196f3; /* Blue */
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}
/* Styling for button hover state */
button:hover {
    background-color: #1976d2; /* Darker Blue */
}
/* Styling for unordered lists */
ul {
    list-style-type: none;
    padding: 0;
}
/* Styling for list items */
li {
    background-color: #333;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9em; /* Adjust the font size here */
}
/* Styling for name entry */
.name-entry {
    font-size: 1.3em; /* Larger font size for the name entry */
    font-weight: bold; /* Optional: make it bold */
}
/* Styling for ghosted markers */
.leaflet-marker-icon.ghosted {
    opacity: 0.5; /* Adjust the opacity to make it ghosted */
}
/* Styling for horizontal rules */
hr {
    border: 0;
    height: 1px;
    background: #444;
    margin: 10px 0;
}
/* Styling for delete buttons */
.delete-button {
    background-color: #9c27b0; /* Red */
    color: #fff;
}
/* Styling for delete button hover state */
.delete-button:hover {
    background-color: #9c27b0; /* Darker Red */
}
/* Styling for button container */
.button-container {
    display: flex;
    gap: 10px; /* Space between buttons */
}

/* New CSS class for <Link> component */
.link {
    color: #61dafb; /* Change this to your desired color */
    text-decoration: none; /* Optional: remove underline */
}

.link:hover {
    text-decoration: underline; /* Optional: underline on hover */
}

/* Import Rock Salt and Lato fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&family=Lato:wght@400;700&display=swap');

/* Styling for h1 and h2 */
h1, h2 {
    font-family: 'Rock Salt', cursive;
}
